function toggleQuestionForm() {
    var messenger = $(".messenger");
    var destination = "-600px";
    var margin = "5px";

    var width = $(document).width();

    if (width < 719) {
        destination = "-420px";
        margin = "5px"
    }
    else if (width < 1023) {
        destination = "-600px";
    }
    else if(width < 1439)
    {
        destination = "-570px";
    }

    if (messenger.css("margin-top") != destination) {
        messenger.stop().animate({
            marginTop: destination
        }, 500, "linear");
    } else {
        messenger.stop().animate({
            marginTop: margin
        }, 500, "linear");
    }
}
$(document).ready(function() {
    $(document).on("click", ".responsive-nav-button", function() {
        $(".header nav").toggle();
    });

    $(document).on("click", "[data-trigger='question-form']", function() {
        toggleQuestionForm();
    });

    $(".slider").owlCarousel({
        items:1,
        autoplay:true,
        nav : false, // Show next and prev buttons
        autoplaySpeed : 300,
        dotsSpeed : 400,
        loop:true,
        margin: 1
    });

    $(".contact-form-status").delay(4000).hide(1000);

    /*
     * define padding when cookie-banner-wrapper is shown or hidden
     */
    $(window).resize(function () {
        if ($(document).width() < 768) {
            var cookiebannerheight = $('.cookie-banner-wrapper').outerHeight();

            if ($('.cookie-banner-wrapper').is(':hidden')) {
                $('.main').css('padding-top', '0');
            } else {
                $('.main').css('padding-top', cookiebannerheight);
            }
        }
    }).trigger("resize");
});
var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};